import React, { useMemo, useCallback, useState, useEffect, ReactElement } from 'react';
import { SubscriptionResult, MutationResult, ApolloCache, MutationHookOptions } from '@fjedi/graphql-react-components';
import {
  NotificationsQuery,
  NotificationsQueryResult,
  NotificationsQueryVariables,
  PageInfo,
  useNotificationChangedSubscription,
  useNotificationCreatedSubscription,
  useNotificationsQuery,
  NotificationsDocument,
} from 'src/graphql/generated';
import logger from 'src/helpers/logger';
import { SpinnerComponent } from 'src/components/ui-kit/scroll-pagination';

export type ScrollPaginationData = Pick<NotificationsQueryResult, 'error' | 'loading'> & {
  rows: NotificationsQuery['notifications']['rows'];
  count: NotificationsQuery['notifications']['count'];
  onScrollFrame: (_ev: { top: number }) => unknown;
  page: number;
  loadingNextPage: boolean;
  nextPageLoader: ReactElement;
  variables: NotificationsQueryVariables;
  update: MutationHookOptions['update'];
  pageInfo: PageInfo;
  onLoadMore: () => void;
  refetch: NotificationsQueryResult['refetch'];
};

export function useNotificationsWithScrollPagination(options?: {
  filterValue?: string;
  rowsPerPage?: number;
  skip?: boolean;
}): ScrollPaginationData {
  const rowsPerPage = options?.rowsPerPage ?? 10;
  const filterValue = options?.filterValue ?? '';
  const skip = options?.skip ?? false;
  const [page, setPage] = useState<number>(0);
  const [loadingNextPage, setLoadingNextPage] = useState<boolean>(false);

  const variables = useMemo<NotificationsQueryVariables>(
    () => ({
      filter: {},
      pagination: {
        limit: rowsPerPage,
        offset: 0,
      },
    }),
    [filterValue, rowsPerPage],
  );
  const queryRes = useNotificationsQuery({
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-and-network',
    variables,
    context: {
      debounceKey: 'notifications-list',
      debounceTimeout: 400,
    },
    skip,
  });
  const { data: res, error, loading, fetchMore, refetch } = queryRes;

  useEffect(() => {
    if (!page) {
      return;
    }
    const v = {
      pagination: {
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      },
    };
    setLoadingNextPage(true);
    fetchMore({
      variables: v,
    })
      .catch(logger)
      .finally(() => setLoadingNextPage(false));
  }, [page, rowsPerPage, fetchMore, setLoadingNextPage]);
  const { count = 0, rows = [], pageInfo = { total: 0, current: 1, hasNextPage: false } } = res?.notifications ?? {};
  const hasNextPage = res?.notifications?.pageInfo?.hasNextPage ?? false;

  const nextPage = pageInfo?.current ?? page + 1;
  const onScrollFrame = useCallback(
    ({ top }: { top: number }) => {
      if (top < 0.8 || loading || !hasNextPage) {
        return;
      }
      setPage(nextPage);
    },
    [nextPage, loading, hasNextPage],
  );
  const onLoadMore = useCallback(() => {
    if (loading || !hasNextPage) {
      return;
    }
    setPage(nextPage);
  }, [loading, rowsPerPage, nextPage, hasNextPage, setPage]);
  //
  const update = useSubscribeToNotifications(variables, refetch);

  return {
    error,
    loading: !rows?.length && loading,
    loadingNextPage,
    nextPageLoader: <SpinnerComponent loading={loadingNextPage} hasNextPage={hasNextPage} />,
    onLoadMore,
    refetch,
    rows,
    count,
    pageInfo,
    onScrollFrame,
    page,
    variables,
    update,
  };
}

export function useSubscribeToNotifications(
  queryVariables: NotificationsQueryVariables,
  refetch: NotificationsQueryResult['refetch'],
): MutationHookOptions['update'] {
  const updateCachedNotifications = useCallback(
    (cache: ApolloCache<unknown>, result: SubscriptionResult | MutationResult) => {},
    [queryVariables],
  );

  useNotificationCreatedSubscription({
    onSubscriptionData({ client, subscriptionData }) {
      const cache = client.cache;
      const cached = cache.readQuery({
        query: NotificationsDocument,
        variables: queryVariables,
      });

      cache.writeQuery({
        query: NotificationsDocument,
        variables: queryVariables,
        data: {
          notifications: {
            ...cached?.notifications,
            count: cached?.notifications?.count + 1,
            rows: [
              subscriptionData.data.notificationCreated,
              ...(cached?.notifications?.rows ?? []),
            ],
          }
        }
      });

      setTimeout(() => {
        refetch().catch((error: any) => {
          logger(`#### useNotificationCreatedSubscription.refetch.error: ${{ error }}`);
        });  
      }, 1000);
      
      // updateCachedNotifications(client.cache, subscriptionData);
    },
  });
  useNotificationChangedSubscription({
    onSubscriptionData({ client, subscriptionData }) {
      updateCachedNotifications(client.cache, subscriptionData);
    },
  });

  return updateCachedNotifications as unknown as MutationHookOptions['update'];
}
