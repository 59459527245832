import React, { FC, memo, useState, useCallback, useEffect, useMemo, Fragment, createElement, ReactNode } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { useTranslation } from 'react-i18next';
import { logger, useApolloError } from '@fjedi/graphql-react-components';
import { List, Badge, type DrawerProps } from 'antd';
import { BellOutlined } from 'src/components/ui-kit/icons';
import Button from 'src/components/ui-kit/buttons';
import Spinner from 'src/components/ui-kit/spinner';
import Drawer from 'src/components/ui-kit/drawer';
import {
  Notification,
  useNotificationsQuery,
  useRemoveAllNotificationsMutation,
  useViewNotificationsMutation,
} from 'src/graphql/generated';
import { useNotificationsWithScrollPagination } from 'src/components/common/notifications-center/hooks';
import Link from 'src/components/ui-kit/buttons/link';
import LinkButton from 'src/components/ui-kit/buttons/link-button';
import { setOpacity } from 'src/components/ui-kit/utilities';
import { useTheme } from 'config/theme';
import { formatDate } from 'src/helpers/time';
import { useLocation } from '@fjedi/react-router-helpers';
import DeleteButton from 'src/components/ui-kit/buttons/delete';
// import GlasButton from 'src/components/ui-kit/buttons/glass';
import Popconfirm from 'src/components/ui-kit/popconfirm';

const Notifications = styled.div`
  @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
    // left: -1.5rem;
    position: relative;
  }

  .anticon {
    cursor: pointer;
    font-size: 1.2rem;
    //padding-top: 1.5rem;
    color: #fff;
    vertical-align: middle;
  }
`;

const StyledBadge = styled(Badge)`
  sup {
    background-color: #444;
    font-size: 11px;
    height: 14px;
    line-height: 14px;
    min-width: 14px;
    padding: 0 4px;
    right: 8px;
    top: 6px;

    &.ant-badge-multiple-words {
      right: 9px;
    }
  }

  .ant-list-item {
    padding: 1rem;
  }
  .ant-list-item-meta-description {
    text-align: left;
  }
`;

const DrawerExtra = styled.div`
  > * {
    margin: 0 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const NotificationsButton = styled(Button)`
  //margin-right: 0.5rem;

  &.ant-btn {
    &,
    &:hover,
    &:focus {
      border: 0;
      background: none;
      width: auto;

      .anticon {
        font-size: 1.2rem;
      }

      @media screen and (max-width: ${props => props.theme.responsiveBreakpoints.mobile}) {
        .anticon {
          font-size: 1.8rem;
        }
      }
    }
  }

  .ant-badge {
    margin-top: 1.5rem;
  }
`;

const ListItem = styled(List.Item)`
  .ant-list-item-action {
    //align-self: flex-end;
    //margin-top: 1rem;
  }

  .ant-list-item-meta-title {
    text-align: left;

    &,
    > a {
      color: ${props => props.theme.token.colorPrimary};
    }
  }
`;

const StyledList = styled(List<Notification>)`
  &.ant-list {
    ${ListItem}.ant-list-item {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      margin: 0.25rem auto;
      align-items: flex-start;
      transition: background-color 3s;

      .ant-list-item-meta-title {
        font-size: 0.95rem;
      }
    }
  }
`;

//
const NotificationsComponent: FC = () => {
  const { t } = useTranslation();
  const [notificationListStatus, setNotificationListStatus] = useState(false);
  const {
    rows,
    count,
    loading,
    loadingNextPage,
    // update,
    pageInfo: { current: currentPage, hasNextPage },
    onLoadMore,
    refetch,
  } = useNotificationsWithScrollPagination({});
  const { data, refetch: refetchCounter } = useNotificationsQuery({
    variables: {
      pagination: {
        limit: 1,
        offset: 0,
      },
      filter: {
        viewed: false,
      },
    },
  });
  const unreadNotificationsCount = get(data, 'notifications.count', 0);

  const toggleNotificationList = useCallback(
    () => setNotificationListStatus(!notificationListStatus),
    [notificationListStatus],
  );
  const getDrawerContainer: DrawerProps['getContainer'] = useCallback<any>(
    () => document.getElementById('layout-content'),
    [],
  );
  const onError = useApolloError();
  const [viewNotifications, { loading: viewing }] = useViewNotificationsMutation({
    onError,
    onCompleted() {
      refetchCounter().catch(logger);
    },
  });

  useEffect(() => {
    const drawerContainer = typeof getDrawerContainer === 'function' ? getDrawerContainer() : null;
    if (drawerContainer) {
      // @ts-ignore
      drawerContainer.parentNode!.style.overflow = notificationListStatus ? 'hidden' : 'auto';
      // @ts-ignore
      drawerContainer.parentNode!.style['max-height'] = notificationListStatus ? '92vh' : 'inherit';
    }
    if (!notificationListStatus) {
      return;
    }

    const ids = compact(rows.filter(n => !n.viewed).map(n => n.id));
    if (!ids.length) {
      return;
    }
    viewNotifications({
      variables: {
        ids,
      },
    }).catch(logger);
  }, [notificationListStatus, currentPage, rows, getDrawerContainer]);

  const loadMore = useMemo(
    () =>
      !loading && hasNextPage ? (
        <div
          style={{
            textAlign: 'center',
            marginTop: 12,
            height: 32,
            lineHeight: '32px',
          }}>
          <Button onClick={onLoadMore} loading={loadingNextPage}>
            Показать еще
          </Button>
        </div>
      ) : null,
    [loading, hasNextPage, onLoadMore],
  );

  const theme = useTheme();
  const renderItem = useCallback(
    (i: unknown) => {
      const item = i as Notification;
      const { id, subject, text, viewed, props } = item;
      const url = props?.url;
      const nodeNumber = props?.nodeNumber;
      const strings = (text || '').replace(/\n/g, '[[[linebreak]]]').split('[[[linebreak]]]');
      const textContent = strings.map((s, sIndex) => {
        const [alarmLabel, alarmState] = s.trim().split(':');
        if (alarmLabel && alarmState) {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={sIndex}>
              {t(alarmLabel.trim())}: {t(alarmState.trim())}
              {sIndex !== strings.length - 1 ? createElement('br') : null}
            </Fragment>
          );  
        }

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={sIndex}>
            {s}
            {sIndex !== strings.length - 1 ? createElement('br') : null}
          </Fragment>
        );
      });
      const actions: ReactNode[] = [t('Date'), formatDate(item.createdAt)];
      if (nodeNumber) {
        actions.push(<Link to={`/dashboard/${nodeNumber}`}>Подробнее</Link>);
      }
      //
      return (
        <ListItem
          key={id}
          actions={actions}
          style={{
            backgroundColor: viewed ? 'rgba(255, 255, 255, 0)' : setOpacity(theme.colorPrimary, 0.2),
          }}>
          <List.Item.Meta title={url ? <Link to={url}>{subject}</Link> : subject} description={textContent} />
        </ListItem>
      );
    },
    [t, theme],
  );
  const getRowKey = useCallback((notification: Notification) => notification.id as React.Key, []);
  const { pathname } = useLocation();
  useEffect(() => {
    setNotificationListStatus(false);
  }, [setNotificationListStatus, pathname]);

  const [removeAllNotifications, { loading: removing }] = useRemoveAllNotificationsMutation({
    onError,
    onCompleted() {
      refetch().catch(logger);
      refetchCounter().catch(logger);
    },
  });

  return (
    <Notifications>
      <StyledBadge count={unreadNotificationsCount}>
        <NotificationsButton icon={<BellOutlined />} shape="circle" onClick={toggleNotificationList} />
      </StyledBadge>
      <Drawer
        width={400}
        placement="right"
        // closable={false}
        onClose={toggleNotificationList}
        open={notificationListStatus}
        getContainer={getDrawerContainer}
        style={{ position: 'absolute' }}
        title={t('Notifications')}
        extra={
          <DrawerExtra>
            <Popconfirm placement="bottomLeft" title="Удалить все уведомления?" onConfirm={removeAllNotifications}>
              <DeleteButton disabled={removing} tooltipProps={{}} />
            </Popconfirm>
            {/* <GlasButton onClick={() => refetch()}/> */}
            <LinkButton to="/profile/settings" type="primary">
              {t('Settings')}
            </LinkButton>
          </DrawerExtra>
        }>
        <StyledList
          rowKey={getRowKey}
          itemLayout="vertical"
          dataSource={rows}
          renderItem={renderItem}
          loadMore={loadMore}>
          {(loading || loadingNextPage) && <Spinner />}
        </StyledList>
      </Drawer>
    </Notifications>
  );
};

NotificationsComponent.propTypes = {};

export default memo(NotificationsComponent);
